import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router'
import VueTheMask from 'vue-the-mask'

import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'

import App from './App.vue'
import { globalMixin } from './mixins/globalMixin.js'
import { createRouter } from '@/router/index.js'
import store from './store/index.js'
import vuetify from './plugins/vuetify.js'
import constants from './plugins/constants.js'
import validateMessages from './plugins/validateMessages.js'
import './assets/styles/app.scss'

Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(VueTheMask)

Vue.mixin(globalMixin)

Vue.prototype.$constants = constants
Vue.prototype.$validateMessages = validateMessages
Vue.prototype.$hasPermission = permission => store.state.auth.permissions.includes(permission)
Vue.prototype.$isAdmin = () => store.state.auth.role.slug === constants.roles.admin
Vue.prototype.$isDirector = () => store.state.auth.role.slug === constants.roles.director
Vue.prototype.$isAgent = () => store.state.auth.role.slug === constants.roles.agent
Vue.prototype.$isManager = () => store.state.auth.role.slug === constants.roles.manager
Vue.prototype.$isEmployee = () => store.state.auth.role.slug === constants.roles.employee
Vue.prototype.$isSvyaznoy = () => store.state.auth.role.slug === constants.roles.svyaznoy
Vue.prototype.$isDirectorEbc = () => store.state.auth.role.slug === constants.roles.directorEbc
Vue.prototype.$isEmployeeEbc = () => store.state.auth.role.slug === constants.roles.employeeEbc
Vue.prototype.$isBankEmployee = () => store.state.auth.role.slug === constants.roles.bankEmployee

Vue.config.productionTip = false

async function init() {
  const router = createRouter()

  await store.dispatch('auth/autoLogin')

  if (store.getters['auth/isAuth']) {
    await store.dispatch('auth/getUser')
  }

  router.beforeEach((to, from, next) => {
    if (store.getters['auth/isAuth']) {
      const permission = to.matched[0].meta.permission

      !permission || store.getters['auth/hasPermission'](permission)
        ? next()
        : next({ name: 'index' })
    } else {
      if (['client-documents', 'login'].includes(to.name)) {
        next()
      } else {
        localStorage.setItem(
          'redirect_url',
          window.location.pathname + window.location.search + window.location.hash
        )

        next({ name: 'login' })
      }
    }
  })

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

void init()
