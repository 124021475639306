<template>
  <VNavigationDrawer
    :value="value"
    fixed
    disable-resize-watcher
    hide-overlay
    style="top: 64px !important"
    @input="$emit('input', $event)"
  >
    <VMenu offset-y>
      <template #activator="{ on, attrs }">
        <div class="profile-nav" v-bind="attrs" v-on="on">
          <VAvatar color="#46cc57" size="40">
            <span class="white--text headline" v-text="avatar" />
          </VAvatar>

          <div class="profile-nav-info">
            <h3 v-text="authUser.surname" />
            <p v-text="$isEmployee() ? `${role.title} ${authUser.login}` : role.title" />
          </div>
        </div>
      </template>

      <VCard>
        <VList nav>
          <VListItem :to="{ name: 'account' }">
            <VListItemTitle>Личный кабинет</VListItemTitle>
          </VListItem>
        </VList>

        <VDivider />

        <VList nav>
          <VListItem @click="logout">
            <VListItemTitle>Выход</VListItemTitle>
          </VListItem>
        </VList>
      </VCard>
    </VMenu>

    <VList nav style="padding-bottom: 160px">
      <template v-for="(link, l) of filteredLinks">
        <VListGroup v-if="link.sublinks" :key="l" no-action class="ebc-list-group">
          <template #activator>
            <VListItemIcon class="mr-6">
              <VIcon>{{ link.icon }}</VIcon>
            </VListItemIcon>

            <VListItemContent>
              <VListItemTitle v-text="link.title" />
            </VListItemContent>
          </template>

          <VListItem v-for="(sublink, s) of link.sublinks" :key="s" link :to="sublink.to">
            <VListItemTitle v-text="sublink.title" />
          </VListItem>
        </VListGroup>

        <VListItem
          v-else
          :key="l"
          :to="link.to || undefined"
          :link="!!link.to"
          :exact="link.exact"
          @click.prevent="!!!link.to && link.event()"
        >
          <VListItemIcon class="mr-6">
            <VIcon>{{ link.icon }}</VIcon>
          </VListItemIcon>

          <VListItemContent>
            <VListItemTitle v-text="link.title" />
          </VListItemContent>
        </VListItem>
      </template>
    </VList>
  </VNavigationDrawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: { type: Boolean, default: true }
  },

  computed: {
    ...mapState('auth', ['authUser', 'role']),

    links: ({ $hasPermission, $constants }) => [
      {
        title: 'Статистика',
        icon: 'mdi-monitor-dashboard',
        to: { name: 'index' },
        visible: $hasPermission($constants.permissions.statistics),
        exact: true
      },
      // { title: 'Директоры', icon: 'mdi-account-tie', to: { name: 'directors' }, visible: this.$hasPermission('directors') },
      {
        title: 'Агенты',
        icon: 'mdi-account-group',
        to: { name: 'agents' },
        visible: $hasPermission($constants.permissions.agents)
      },
      {
        title: 'Менеджеры',
        icon: 'mdi-account-group-outline',
        to: { name: 'managers' },
        visible: $hasPermission($constants.permissions.managers)
      },
      // {
      //   title: 'Города',
      //   icon: 'mdi-city-variant-outline',
      //   to: { name: 'cities' },
      //   visible: $hasPermission($constants.permissions.cities)
      // },
      {
        title: 'Сотрудники ЕБЦ',
        icon: 'mdi-account-group-outline',
        sublinks: [
          {
            title: 'Директора',
            to: { name: 'directors-ebc' },
            visible: $hasPermission($constants.permissions.directorsEbc)
          },
          {
            title: 'Сотрудники',
            to: { name: 'employees' },
            visible: $hasPermission($constants.permissions.employees)
          }
        ],
        visible: $hasPermission($constants.permissions.employees)
      },
      {
        title: 'Сотрудники Банка',
        icon: 'mdi-account-group-outline',
        to: { name: 'bank-employees' },
        visible: $hasPermission($constants.permissions.bankEmployees)
      },
      {
        title: 'Расчетный счет',
        icon: 'fas fa-file-invoice-dollar',
        to: { name: 'billing-accounts' },
        visible: $hasPermission($constants.permissions.billingAccounts)
      },
      {
        title: 'Регистрация ИП/ООО',
        icon: 'mdi-account-group-outline',
        to: { name: 'companies' },
        visible: $hasPermission($constants.permissions.companies)
      },
      {
        title: 'Банковские гарантии',
        icon: 'mdi-account-group-outline',
        to: { name: 'bank-guarantees' },
        visible: $hasPermission($constants.permissions.bankGuarantees)
      },
      {
        title: 'Кредит Бизнесу',
        icon: 'mdi-credit-card-check-outline',
        to: { name: 'credits' },
        visible: $hasPermission($constants.permissions.credits)
      },
      {
        title: 'Взаиморасчеты',
        icon: 'fas fa-ruble-sign',
        to: { name: 'mutual-calculations' },
        visible: $hasPermission($constants.permissions.mutualCalculations)
      },
      {
        title: 'Настройки',
        icon: 'mdi-cog-outline',
        sublinks: [
          {
            title: 'Банки',
            to: { name: 'settings-banks' },
            visible: $hasPermission($constants.permissions.banks)
          },
          {
            title: 'Статусы',
            to: { name: 'settings-statuses' },
            visible: $hasPermission($constants.permissions.statuses)
          },
          {
            title: 'Документы',
            to: { name: 'settings-documents' },
            visible: $hasPermission($constants.permissions.documents)
          },
          {
            title: 'Продукты',
            to: { name: 'settings-products' },
            // visible: $hasPermission($constants.permissions.products)
            visible: true
          },
          {
            title: 'Роли',
            to: { name: 'settings-roles' },
            visible: $hasPermission($constants.permissions.roles)
          },
          {
            title: 'Права',
            to: { name: 'settings-permissions' },
            visible: $hasPermission($constants.permissions.permissions)
          }
        ],
        visible: [
          $hasPermission($constants.permissions.banks),
          $hasPermission($constants.permissions.statuses),
          $hasPermission($constants.permissions.roles),
          $hasPermission($constants.permissions.permissions)
        ].includes(true)
      }
    ],

    filteredLinks: vm =>
      vm.links
        .filter(link => link.visible)
        .map(link => ({
          ...link,
          ...(link.sublinks && { sublinks: link.sublinks.filter(sublink => sublink.visible) })
        })),

    avatar: ({ authUser }) =>
      authUser.surname && `${authUser.surname.charAt(0)}${authUser.name.charAt(0)}`
  },

  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      await this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  ::v-deep {
    .v-list {
      padding-bottom: 10rem;
    }

    .v-navigation-drawer {
      top: 64px !important;
    }
  }
}

.ebc-list-group ::v-deep .v-list-item__icon {
  min-width: inherit !important;
}
</style>
